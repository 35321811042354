<template>

    <div class="justify-content-md-center">
        <div class="alert-info text-center">
            Cargando...
            <h3 class="mt-2">
                <i class="fa fa-spin fa-sync"></i>
            </h3>
        </div>
    </div>
  
</template>

<script>

export default {

    setup() {


        return {

        }
    }

}
</script>

<style lang="scss" scoped>

</style>